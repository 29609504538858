// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Icon from "../components/Icon/Icon.res.js";
import * as JsxRuntime from "react/jsx-runtime";

function IconWeb(props) {
  var __title = props.title;
  var title = __title !== undefined ? __title : "Vision";
  return JsxRuntime.jsx(Icon.make, {
              title: title,
              size: props.size,
              className: props.className,
              children: JsxRuntime.jsx("svg", {
                    children: JsxRuntime.jsx("g", {
                          children: JsxRuntime.jsx("g", {
                                children: JsxRuntime.jsx("g", {
                                      children: JsxRuntime.jsx("path", {
                                            d: "M3,2 L17,2 C17.5522847,2 18,2.44771525 18,3 L18,17 C18,17.5522847 17.5522847,18 17,18 L3,18 C2.44771525,18 2,17.5522847 2,17 L2,3 C2,2.44771525 2.44771525,2 3,2 Z M3,7 L3,17 L17,17 L17,7 L3,7 Z M3,4 L3,5 L4,5 L4,4 L3,4 Z M5,4 L5,5 L6,5 L6,4 L5,4 Z M5,9 L15,9 L15,12 L5,12 L5,9 Z M5,13 L11,13 L11,15 L5,15 L5,13 Z M12,13 L15,13 L15,15 L12,15 L12,13 Z"
                                          }),
                                      transform: "translate(40.000000, 285.000000)"
                                    }),
                                fill: "#98A0B0",
                                fillRule: "nonzero",
                                transform: "translate(-42.000000, -287.000000)"
                              }),
                          fill: "none",
                          fillRule: "evenodd",
                          stroke: Icon.mapColor(props.color),
                          strokeWidth: "1"
                        }),
                    height: "16px",
                    width: "16px",
                    version: "1.1",
                    viewBox: "0 0 16 16",
                    xmlns: "http://www.w3.org/2000/svg"
                  })
            });
}

var make = IconWeb;

export {
  make ,
}
/* Icon Not a pure module */
